<template>
  <v-dialog
    v-if="modalData"
    v-model="modalData.dialog"
    max-width="950px"
    persistent
    scrollable
    :retain-focus="false"
    basicstyles
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- v-if="permissionCan('create')" -->
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.contact_form_type") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                :rules="nameRules"
                v-model="formModel.translations[selectedLocale.lang].name"
                :label="$t('FORMS.name')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <!--
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-if="formModel"
                v-model="formModel.timeout"
                :label="$t('FORMS.timeout')"
                :id="dynamicID"
                :error-messages="messages['timeout']"
                @keyup="messages['timeout'] = ''"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-switch
                v-if="formModel"
                v-model="formModel.transaction"
                hide-details
                inset
                :label="$t('FORMS.transaction')"
              ></v-switch>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-if="formModel"
                v-model="formModel.recipients"
                :label="$t('FORM_INPUT_NAMES.recipient')"
                :id="dynamicID"
                :error-messages="messages['recipients']"
                @keyup="messages['recipients'] = ''"
              ></v-text-field>
            </v-col>
          </v-row>
          -->

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-combobox
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="
                  formModel.translations[selectedLocale.lang].admin_emails
                "
                :items="
                  formModel.translations[selectedLocale.lang].admin_emails
                "
                :label="$t('FORM_INPUT_NAMES.admin_email_adresses')"
                multiple
              >
                <template #selection="{ item }">
                  <v-chip
                    close
                    color="bg-gray-100"
                    @click:close="
                      deleteChipComboBox(
                        item,
                        formModel.translations[selectedLocale.lang].admin_emails
                      )
                    "
                    >{{ item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-col>

            <!--
            <v-col cols="12" sm="12" md="12">              
              <v-text-field
                v-if="
                  formModel.translations &&
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].webhook_urls
                "
                v-model="
                  formModel.translations[selectedLocale.lang].webhook_urls[0]
                "
                :label="$t('FORM_INPUT_NAMES.webhook_URL')"
              ></v-text-field>
            </v-col>
            -->
          </v-row>
        </v-form>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="blue darken-1"
          text
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions> -->
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./ContactFormTypes";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
//import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";

const TRANSLATED_ATRIBUTES = [
  "name", 
  "admin_emails"
  //"webhook_urls"
];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  // "seoTitle",
  // "seoDescription",
  // "desktop_image",
  // "mobile_image",
];

export const initialFormData = () => ({
  id: null,
  transaction: 0,
  //timeout: null,
  //transaction: 0,
  translations: {},
  //recipients: [],
});

export default {
  name: "ContactFormTypeModalForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  //components: { CustomFieldComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
    };
  },
  computed: {
    // ...mapGetters([CURRENCY_COLLECTION]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") +
            " " +
            this.$t("MENU.contact_form_type").toLowerCase();
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => { console.log(data );
              data.status = 1 * data.status;
              /*if (!data.recipients) {
                data.recipients = [];
              }*/
              /*if (
                data.translations &&
                data.translations[this.selectedLocale.lang] &&
                !data.translations[this.selectedLocale.lang].webhook_urls
              ) {
                data.translations[this.selectedLocale.lang].webhook_urls = [];
                data.translations[this.selectedLocale.lang].webhook_urls[0] =
                  "";
              }*/
              if (
                data.translations &&
                data.translations[this.selectedLocale.lang] &&
                !data.translations[this.selectedLocale.lang].admin_emails
              ) {
                data.translations[this.selectedLocale.lang].admin_emails = [];
              }
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              // this.formModel.custom_fields = this.initCustomFields();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;

        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    deleteChipComboBox(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove) {
          array.splice(i, 1);
        }
      }
    },

    setTranslatedAttributes() {
      if (this.formModel && this.formModel.translations) {
        if (!this.formModel.id) {
          this.languages.forEach((language) => {
            this.translatedAttributes.forEach((attribute) => {
              if (!this.formModel.translations[language.lang]) {
                // this.formModel.translations.push(language.lang);
                this.formModel.translations[language.lang] = {};
              }
              this.formModel.translations[language.lang][attribute] = null;
              if (attribute == "admin_emails" || attribute == "webhook_urls") {
                this.formModel.translations[language.lang][attribute] = [];
              }
            });
            // if (this.formModel.translations[language.lang]) {
            //   this.formModel.translations[language.lang]["attachments"] = [];
            // }
          });
        } else {
          this.languages.forEach((language) => {
            this.translatedAttributes.forEach((attribute) => {
              if (!this.formModel.translations[language.lang]) {
                this.formModel.translations[language.lang] = {};
                this.formModel.translations[language.lang][attribute] = null;
              }
            });
          });
        }
      }
      this.initTranslatedCustomFields();
    },

    deleteUnusedTranslations(model) {
      let translations = {};
      if (this.translatedAttributes.length > 0) {
        i18nService.languages.forEach((item) => {
          let langCode = item.lang;

          this.translatedAttributes.forEach((attribute) => {
            if (
              model.translations[langCode] &&
              model.translations[langCode][attribute] &&
              attribute != "custom_fields" &&
              attribute != "admin_emails" &&
              attribute != "webhook_urls"
            ) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
        });
      }

      model.translations = translations;

      return model;
    },
  },

  mounted() {
    this.setTranslatedAttributes();
    // console.log("modalData", this.modalData);
    // if (!this.modalData) {
    //   this.modalData = { dialog: true, itemId: 5 };
    // }
  },
};
</script>